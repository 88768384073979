import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import AddCostume from './components/AddCostume';
import AddArtist from './components/AddArtist';

function App() {
    return (
        <Router>
            <Navbar />
            <div className="container mt-3">
                <Routes>
                    <Route path="/add-costume" element={<AddCostume/>} />
                    <Route path="/add-artist" element={<AddArtist/>} />
                    {/* Add other routes here */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
