import React, { useState } from 'react';

const AddArtist = () => {
    const [form, setForm] = useState({
        artist_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        category: '',
        assigned_costume_number: ''
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://magazie.folclorbanat.ro/api/add_artist.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        });
        const result = await response.json();
        if (result.success) {
            alert('Artist added successfully');
            setForm({
                artist_id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                category: '',
                assigned_costume_number: ''
            });
        } else {
            alert('Error adding artist');
        }
    };

    return (
        <div className="container">
            <h2>Adauga membru nou</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Cod de identificare*:</label>
                    <input type="text" className="form-control" name="artist_id" value={form.artist_id} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Prenume*:</label>
                    <input type="text" className="form-control" name="first_name" value={form.first_name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Nume de familie*:</label>
                    <input type="text" className="form-control" name="last_name" value={form.last_name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Email*:</label>
                    <input type="email" className="form-control" name="email" value={form.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Numar de telefon*:</label>
                    <input type="text" className="form-control" name="phone_number" value={form.phone_number} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Categorie*:</label>
                    <select className="form-control" name="category" value={form.category} onChange={handleChange} required>
                        <option value="">Selecteaza categoria</option>
                        <option value="student">Student</option>
                        <option value="veteran">Veteran</option>
                        <option value="scoala dans">Scoala dans</option>
                        <option value="extern">Extern</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Numarul de costum alocat:</label>
                    <input type="text" className="form-control" name="assigned_costume_number" value={form.assigned_costume_number} onChange={handleChange} />
                </div>
                <button type="submit" className="btn btn-primary btn-block">Adauga membru</button>
            </form>
        </div>
    );
};

export default AddArtist;
