import React, { useState } from 'react';

const AddCostume = () => {
    const [form, setForm] = useState({
        item_id: '',
        gender: '',
        type_of_clothing: '',
        region: '',
        image: '',
        special_note: ''
    });

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://magazie.folclorbanat.ro/api/add_costume.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        });
        const result = await response.json();
        if (result.success) {
            alert('Costume added successfully');
            setForm({
                item_id: '',
                gender: '',
                type_of_clothing: '',
                region: '',
                image: '',
                special_note: ''
            });
        } else {
            alert('Error adding costume');
        }
    };

    return (
        <div className="container">
            <h2>Adauga articol</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>ID articol*:</label>
                    <input type="text" className="form-control" name="item_id" value={form.item_id} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Gen:</label>
                    <select className="form-control" name="gender" value={form.gender} onChange={handleChange} required>
                        <option value="">Selecteaza genul*</option>
                        <option value="baiat">Baiat</option>
                        <option value="fata">Fata</option>
                        <option value="universal">Universal</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Tipul articolului*:</label>
                    <input type="text" className="form-control" name="type_of_clothing" value={form.type_of_clothing} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Regiune*:</label>
                    <select className="form-control" name="region" value={form.region} onChange={handleChange} required>
                        <option value="">Selecteaza regiunea</option>
                        <option value="Banat Luncani">Banat Luncani</option>
                        <option value="Banat Resita">Banat Resita</option>
                        <option value="Banat Negru">Banat Negru</option>
                        <option value="Oltenia">Oltenia</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Nasaud">Nasaud</option>
                        <option value="Bihor">Bihor</option>
                        <option value="Dobrogea">Dobrogea</option>
                        <option value="Universal">Universal</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Link imagine:</label>
                    <input type="text" className="form-control" name="image" value={form.image} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Observatii:</label>
                    <textarea className="form-control" name="special_note" value={form.special_note} onChange={handleChange}></textarea>
                </div>
                <button type="submit" className="btn btn-primary btn-block">Adauga articol</button>
            </form>
        </div>
    );
};

export default AddCostume;
