import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="https://magazie.folclorbanat.ro/api/admin.php">Admin Panel</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/add-costume">Add Costume</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/add-artist">Add Artist</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/borrow-costumes">Borrow Costumes</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/return-costumes">Return Costumes</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/borrowed-costumes">Currently Borrowed Costumes</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
